import PlentyBody from '#app/components/typography/plenty-body'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import PlentyBodyLink from '#app/components/ui/plenty-body-link'
import { type CampaignStoryblok } from '#types/component-types-sb'

interface CampaignTextOnlyProps {
	blok: CampaignStoryblok
}

const CampaignTextOnly = ({ blok }: CampaignTextOnlyProps) => {
	return (
		<div className="flex flex-col gap-2 px-4">
			<PlentyButtonsAndLinks as="nav-sm">{blok.headline}</PlentyButtonsAndLinks>
			<PlentyBody>{blok.bodyTextCampaign}</PlentyBody>
			<PlentyBodyLink link="stores" as="nav-sm">
				{blok.linkAndText}
			</PlentyBodyLink>
		</div>
	)
}

export default CampaignTextOnly
