import { type ExternalScriptsHandle } from 'remix-utils/external-scripts'

export const klaviyoScript: ExternalScriptsHandle = {
	scripts: [
	  {
		src: 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=TViZeW',
		async: true,
		type: 'text/javascript',
	  },
	],
  };
