import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	type CampaignStoryblok,
	type CampaignCallerStoryblok,
} from '#types/component-types-sb'
import PlentyBody from '../typography/plenty-body'
import { Skeleton } from '../ui/skeleton'
import Campaign from './campaign'

interface CampaignCallerProps {
	blok: CampaignCallerStoryblok
}

const CampaignCaller = ({ blok }: CampaignCallerProps) => {
	// State to store campaigns
	const [campaigns, setCampaigns] = useState<any[]>([])
	const [isFetching, setIsFetching] = useState<boolean>(false)
	const { t } = useTranslation('common')

	useEffect(() => {
		async function fetchCampaigns() {
			setIsFetching(true)
			try {
				const storedCampaigns = sessionStorage.getItem('mobileNavCampaigns')
				if (storedCampaigns) {
					setCampaigns(JSON.parse(storedCampaigns))
					setIsFetching(false)
					return
				}

				const response = await fetch('/resources/storyblok/mobile-nav-campaign')
				if (!response.ok) {
					throw new Error('Failed to fetch campaigns')
				}
				const data = await response.json()

				setCampaigns(data.mobileNavCampaigns)
				sessionStorage.setItem(
					'mobileNavCampaigns',
					JSON.stringify(data.mobileNavCampaigns),
				)
			} catch (error) {
				console.error('Failed to fetch campaigns:', error)
			} finally {
				setIsFetching(false)
			}
		}

		fetchCampaigns()
	}, [])

	// Extract the campaign UUID from blok
	const selectedCampaignUUID =
		blok.campaignReference && blok.campaignReference.length > 0
			? blok.campaignReference[0]
			: null

	// Find the campaign by UUID
	const selectedCampaign =
		campaigns.length > 0 && selectedCampaignUUID
			? campaigns.find(
					(campaign: { uuid: string }) =>
						campaign.uuid === selectedCampaignUUID,
			  )
			: null

	// Assert the type of the campaign content to match CampaignStoryblok
	const campaignContent = selectedCampaign?.content as
		| CampaignStoryblok
		| undefined

	// Render the campaign if it's found, otherwise show an error or fallback
	return (
		<div>
			{isFetching ? (
				<div className="flex w-full justify-between gap-2 px-4">
					{/* Two side-by-side Skeleton components with a dynamic 3:4 aspect ratio */}
					<Skeleton className="aspect-[3/4] w-[48%]" />
					<Skeleton className="aspect-[3/4] w-[48%]" />
				</div>
			) : campaignContent ? (
				<Campaign blok={campaignContent} />
			) : (
				<PlentyBody>{t('common:no_content_found')}</PlentyBody>
			)}
		</div>
	)
}

export default CampaignCaller
