import { Link } from '@remix-run/react'
import { type FullscreenImageStoryblok } from '#types/component-types-sb'
import BlackBackgroundColorForImages from '../black-background-color-for-images'
import ContentBlok from './content-blok'

const FullScreenImageBlok = ({ blok }: { blok: FullscreenImageStoryblok }) => {
	const imageUrlDesktop = blok.FullscreenImageDesktop?.filename || ''
	const imageUrlMobile = blok.FullscreenImageMobile?.filename || imageUrlDesktop
	const link = blok.imageClickLink
	const minHeightClass = blok.minHeightOf250px ? 'min-h-[250px]' : ''
	const renderContentBlock = (block: any, index: number) => {
		return (
			<ContentBlok
				blok={block}
				key={`${block._uid}-${index}`}
				_uid={''}
				component={'contentBlok'}
			/>
		)
	}

	const contentBlok = Array.isArray(blok.contentBlok) ? blok.contentBlok : []
	const paddingClasses = blok.paddingY ? 'md:py-28 py-14' : ''
	return (
		<div className={`w-full ${paddingClasses}`}>
			<div className="group relative overflow-hidden">
				{link ? (
					<Link to={link}>
						<picture>
							{imageUrlMobile && (
								<source media="(max-width: 819px)" srcSet={imageUrlMobile} />
							)}
							{imageUrlDesktop && (
								<source media="(min-width: 820px)" srcSet={imageUrlDesktop} />
							)}

							<img
								src={imageUrlDesktop}
								alt=""
								fetchpriority="high"
								decoding="sync"
								className={`max-h-[600px] w-full object-cover object-top transition-all duration-300 ease-out group-hover:scale-105 ${minHeightClass}`}
							/>
						</picture>
					</Link>
				) : (
					<picture>
						{imageUrlMobile && (
							<source media="(max-width: 819px)" srcSet={imageUrlMobile} />
						)}
						{imageUrlDesktop && (
							<source media="(min-width: 820px)" srcSet={imageUrlDesktop} />
						)}

						<img
							src={imageUrlDesktop}
							alt=""
							fetchpriority="high"
							decoding="sync"
							className={`max-h-[600px] w-full object-cover object-top transition-all duration-300 ease-out group-hover:scale-105 ${minHeightClass}`}
						/>
					</picture>
				)}
				{/* Overlay div added here */}

				{!blok.removeOverlay && <BlackBackgroundColorForImages />}
				{!imageUrlDesktop && <p>Image not available</p>}

				<div className="absolute bottom-0 left-0 right-0 mx-auto w-full px-4 pb-8 md:px-[var(--plenty-padding)] md:pb-[5.05%] 3xl:mx-0">
					{contentBlok.map(renderContentBlock)}
				</div>
			</div>
		</div>
	)
}
export default FullScreenImageBlok
