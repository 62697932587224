import {
	storyblokEditable,
	StoryblokComponent,
	type SbBlokData,
} from '@storyblok/react'
import { ClientOnly } from 'remix-utils/client-only'
import useAuthenticatedUser from '#app/hooks/use-authenticated-user'
import { type MobileGridColumnsStoryblok } from '#types/component-types-sb'

const MobileGridColumns = ({ blok }: MobileGridColumnsStoryblok) => {
	const numberOfColumns = parseInt(blok.numberOfColumns, 10)
	const user = useAuthenticatedUser()
	const columnGap = 'gap-2' // Default gap size if not specified

	// Define the container classes for the grid layout
	let containerClasses = `grid ${columnGap} auto-rows-auto place-items-center`

	// Define the item classes for individual grid items
	let itemClasses = `bg-beige-50-opacity flex w-full items-center w-[90%] justify-center h-full rounded-[4px]`

	// Filter the columns based on user authentication status
	const filteredColumns = blok.columns.filter(
		(column: { hideForAuthUsers?: boolean }) => {
			if (user && column.hideForAuthUsers) return false
			return true
		},
	)
	// Set the grid layout based on the number of columns
	switch (numberOfColumns) {
		case 2:
			containerClasses += ' grid-cols-2'
			break
		case 3:
			containerClasses += ' grid-cols-3'
			break

		case 4:
			containerClasses += ' grid-cols-4'
			break

		case 6:
			containerClasses += 'grid-cols-6'
			break
		case 8:
			containerClasses += 'grid-cols-8'
			break
		default:
			containerClasses += ' grid-cols-2'
			break
	}

	return (
		<ClientOnly fallback={<></>}>
			{() => (
				<ul
					{...storyblokEditable(blok)}
					key={blok._uid}
					className={containerClasses}
				>
					{filteredColumns.map((blokColumn: SbBlokData) => (
						<li key={blokColumn._uid} className={itemClasses}>
							<StoryblokComponent blok={blokColumn} />
						</li>
					))}
				</ul>
			)}
		</ClientOnly>
	)
}

export default MobileGridColumns
