import { Link } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import BlackBackgroundColorForImages from '#app/components/black-background-color-for-images'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import { type CampaignStoryblok } from '#types/component-types-sb'

interface CampaignsTwoImagesProps {
	blok: CampaignStoryblok
	getTextPositionClass: (position: string) => string
}

const CampaignsTwoImages = ({
	blok,
	getTextPositionClass,
}: CampaignsTwoImagesProps) => {
	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="relative mx-auto flex w-full max-w-screen-lg flex-col items-center py-3"
		>
			<div className="flex w-full justify-between space-x-2 px-4">
				{blok.twoImage?.map((image: any, index: number) => (
					<div
						key={index}
						className="group relative w-1/2 overflow-hidden rounded-[8px] bg-cover bg-center bg-no-repeat md:rounded-[16px]"
					>
						<Link
							to={
								(index === 0
									? blok.linkToPageCampaignTwoImagesFirstImage
									: blok.linkToPageCampaignTwoImagesSecondImage) || '/'
							}
							className="z-10 overflow-hidden"
						>
							<div className="relative rounded-[8px]">
								{/* Image */}
								<img
									src={image.filename}
									alt={image.alt || `image-${index}`}
									className="w-full rounded-[8px] object-cover"
									fetchpriority="high"
								/>

								{/* Black Background */}
								{!blok.removeOverlay && (
									<BlackBackgroundColorForImages
										className={
											blok.textPlacement === 'underImage' ? 'rounded-[8px]' : ''
										}
									/>
								)}
								{/* Text based on Position */}
								{blok.textPlacement === 'centerImage' && (
									<PlentyButtonsAndLinks
										as="nav-sm"
										className={`absolute inset-0 z-20 flex items-center justify-center text-center text-${blok.textColor}`}
									>
										{index === 0
											? blok.twoImageBodyFirstImage
											: blok.twoImageBodySecondImage}
									</PlentyButtonsAndLinks>
								)}

								{blok.textPlacement === 'downLeftCorner' && (
									<PlentyButtonsAndLinks
										as="nav-sm"
										className={`absolute bottom-0 left-0 z-20 p-2 text-${blok.textColor}`}
									>
										{index === 0
											? blok.twoImageBodyFirstImage
											: blok.twoImageBodySecondImage}
									</PlentyButtonsAndLinks>
								)}
							</div>
						</Link>

						{/* Text below the image */}
						{blok.textPlacement === 'underImage' && (
							<PlentyButtonsAndLinks as="nav-sm" className="mt-2 text-center">
								{index === 0
									? blok.twoImageBodyFirstImage
									: blok.twoImageBodySecondImage}
							</PlentyButtonsAndLinks>
						)}
					</div>
				))}
			</div>
		</div>
	)
}
export default CampaignsTwoImages
