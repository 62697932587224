import { Link } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import BlackBackgroundColorForImages from '#app/components/black-background-color-for-images'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import {
	Carousel,
	CarouselContent,
	CarouselItem,
} from '#app/components/ui/carousel'
import { type CampaignStoryblok } from '#types/component-types-sb'

interface CampaignFourImagesProps {
	blok: CampaignStoryblok
	getTextPositionClass: (position: string) => string
}

const CampaignFourImages = ({
	blok,
	getTextPositionClass,
}: CampaignFourImagesProps) => {
	// Function to render the text based on position
	const renderText = (index: number) => {
		const text = [
			blok.fourImageBodyFirstImage,
			blok.fourImageBodySecondImage,
			blok.fourImageBodyThirdImage,
			blok.fourImageBodyFourthImage,
		][index]

		if (!text) return null

		if (blok.textPlacement === 'centerImage') {
			return (
				<PlentyButtonsAndLinks
					as="nav-sm"
					className={`absolute inset-0 z-20 flex items-center justify-center text-${blok.textColor}`}
				>
					{text}
				</PlentyButtonsAndLinks>
			)
		}

		if (blok.textPlacement === 'underImage') {
			return (
				<PlentyButtonsAndLinks as="nav-sm" className="mt-2 text-center">
					{text}
				</PlentyButtonsAndLinks>
			)
		}

		return (
			<PlentyButtonsAndLinks
				as="nav-sm"
				className={getTextPositionClass(blok.textPlacement as string)}
			>
				{text}
			</PlentyButtonsAndLinks>
		)
	}

	// Function to render a single image item
	const renderImageItem = (image: any, index: number) => {
		return (
			<div key={index} className="relative w-full">
				<Link
					to={
						(index === 0
							? blok.linkToPageCampaignFourImagesFirstImage
							: index === 1
							  ? blok.linkToPageCampaignFourImagesSecondImage
							  : index === 2
							    ? blok.linkToPageCampaignFourImagesThirdImage
							    : blok.linkToPageCampaignFourImagesFourthImage) || '/'
					}
				>
					<div className="relative">
						{/* Image */}
						<img
							src={image.filename}
							alt={image.alt || `image-${index}`}
							className="h-auto w-full rounded-[8px] object-cover"
						/>

						{/* Black Background */}
						{!blok.removeOverlay && (
							<BlackBackgroundColorForImages className="rounded-[8px]" />
						)}
					</div>
				</Link>

				{/* Render Text */}
				{renderText(index)}
			</div>
		)
	}

	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="relative mx-auto flex w-full max-w-screen-lg flex-col items-center py-3"
		>
			{blok.ShouldBeASlider ? (
				<Carousel
					className="relative w-full"
					plugins={[WheelGesturesPlugin()]}
					opts={{ dragFree: true }}
				>
					<CarouselContent key={blok._uid} className="mx-4 gap-4">
						{blok.fourImages?.map((image: any, index: number) => (
							<CarouselItem
								key={index}
								className="-pl-4 relative w-full max-w-[192px] shrink-0"
							>
								{renderImageItem(image, index)}
							</CarouselItem>
						))}
					</CarouselContent>
				</Carousel>
			) : (
				<div className="grid grid-cols-2 gap-4 px-4">
					{blok.fourImages?.map((image: any, index: number) =>
						renderImageItem(image, index),
					)}
				</div>
			)}
		</div>
	)
}

export default CampaignFourImages
