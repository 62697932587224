import {
	richTextResolver,
	type StoryblokRichTextNode,
} from '@storyblok/richtext'
import { type RichtextStoryblok } from '#types/component-types-sb'
import renderEmojis from './emoji-renderer'

const RichText = ({ blok }: RichtextStoryblok) => {
	const resolver = richTextResolver()

	// Ensure blok?.richText is properly typed and cast it to StoryblokRichTextNode
	const richText = blok?.richText as StoryblokRichTextNode | undefined

	// Convert the rich text field to an HTML string if it exists
	const richTextHTMLString = richText ? resolver.render(richText) : ''

	// Process the HTML string with renderEmojis
	const richTextHTML = renderEmojis(richTextHTMLString as string, {})

	return (
		<div className="flex w-full flex-col items-center justify-center text-center md:flex-row">
			{richTextHTML}
		</div>
	)
}

export default RichText
