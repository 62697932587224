import { useEffect } from 'react'
import { Toaster, toast as showToast } from 'sonner'
import { type Toast } from '#app/cookies/toast.server'
import { Icon } from './ui/icon'

export function EpicToaster({ toast }: { toast?: Toast | null }) {
	return (
		<>
			<Toaster
				position="bottom-right"
				toastOptions={{
					unstyled: true,
					classNames: {
						error:
							'bg-red-10 text-red-90 min-h-[48px] space-x-2 w-full px-4 py-2 rounded-lg flex flex-row justify-start items-center',
						success:
							'text-black-90 w-full bg-mint-green-80p px-4 py-2 rounded-lg flex flex-row justify-start items-center min-h-[48px] space-x-2',
						info: 'bg-black-90 text-white min-h-[48px] space-x-2 w-full px-4 py-2 rounded-lg flex flex-row justify-start items-center',
						default:
							'bg-beige-50 text-black-90 min-h-[48px] space-x-2 w-full px-4 py-2 rounded-lg flex flex-row justify-start items-center',
					},
				}}
				icons={{
					success: <Icon name="exclamation" size="lg" />,
					info: <Icon name="exclamation" size="lg" />,
					warning: <Icon name="exclamation" size="lg" />,
					error: <Icon name="exclamation" size="lg" />,
					loading: <Icon name="exclamation" size="lg" />,
				}}
			/>
			{toast ? <ShowToast toast={toast} /> : null}
		</>
	)
}

function ShowToast({ toast }: { toast: Toast }) {
	const { id, type, title, description } = toast
	useEffect(() => {
		setTimeout(() => {
			showToast[type](title, { id, description })
		}, 0)
	}, [description, id, title, type])
	return null
}
